import { Col, Row, Button, Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { Input } from "reactstrap";
import CustomModal from "../../../plugins/CustomModal";
import "../../../../assets/css/ToggleSwitch.css";
import { HiOutlineTrash } from "react-icons/hi";
const PlanFeatureMapping = (props) => {
    // const roleId = props.id;
    const roleName = props.name;
    const [roleId, setRoleId] = useState(null);
    const [featureList, setFeatureList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [newFeatureName, setNewFeatureName] = useState("");
    const [newFeatureDesc, setNewFeatureDesc] = useState("");
    const [featureNameError, setFeatureNameError] = useState("");
    const [featureDesError, setFeatureDesError] = useState("");
    const [editedFeatureId, setEditedFeatureId] = useState(null);
    const [deletePopupShow, setDeletePopupShow] = useState(false);
    const [toDelFeatureId, setToDelFeatureId] = useState(null);
    const [planList, setPlanList] = useState([]);
    const toast = useToast();
    useEffect(() => {
        const loadData = async () => {
            if (roleId) {
                await loadAllPlanFeatures();
                await loadFeatureById(roleId);
            }
        };
        loadData();
    }, [roleId]);
    useEffect(() => {
        loadPlans();
    }, []);
    const loadPlans = async () => {
        try {
            const response = await window.apiService.loadPlans();
            setPlanList(response.data.recordInfo);
            setRoleId(response.data.recordInfo[0]?.id);
        } catch (error) {
            console.log(error);
        }
    };
    const showDeletePopup = (id) => {
        setToDelFeatureId(id);
        setDeletePopupShow(true);
    };
    const addFeature = async () => {
        setFeatureNameError("");
        setFeatureDesError("");
        if (!newFeatureName) {
            setFeatureNameError("Feature Name is required.");
            return;
        }
        if (!newFeatureDesc) {
            setFeatureDesError("Feature description is required.");
            return;
        }
        const params = {
            featureName: newFeatureName,
            description: newFeatureDesc,
            id: editedFeatureId,
        };
        try {
            const response = await window.apiService.addPlanFeature(params);
            if (response.data.status === "OK") {
                if (response.data.message === "Service Plan Feature already exists.") {
                    toast.info(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setNewFeatureName("");
                    setNewFeatureDesc("");
                    setEditedFeatureId(null);
                    setEditMode(false);
                    loadAllPlanFeatures();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadAllFeatures();
            const allFeatures = response.data.recordInfo;
            const checkedFeatureIds = new Set(checkedList.map(feature => feature.featureId));
            const updatedFeatureList = allFeatures.map((feature) => ({
                ...feature,
                isChecked: checkedFeatureIds.has(feature.id)
            }));

            setFeatureList(updatedFeatureList);
        } catch (error) {
            console.log(error);
        }
    };
    const loadFeatureById = async (id) => {
        if (id) {
            try {
                const response = await window.apiService.loadFeatureById(id);
                const mappedFeatures = response.data.recordInfo || [];
                const mappedFeatureIds = new Set(mappedFeatures.map((mf) => mf.id));
                setFeatureList((prevList) =>
                    prevList.map((feature) => ({
                        ...feature,
                        isChecked: mappedFeatureIds.has(feature.id)
                    }))
                );
                setCheckedList(mappedFeatures.map((mf) => ({ planId: roleId, featureId: mf.id })));
            } catch (error) {
                console.error("Error loading features by plan ID:", error);
            }
        }
    };

    const handleCheckboxChange = (event, featureId) => {
        const isChecked = event.target.checked;
        setFeatureList((prevList) =>
            prevList.map((feature) =>
                feature.id === featureId ? { ...feature, isChecked } : feature
            )
        );
        setCheckedList((prevCheckedList) => {
            if (isChecked) {
                return [...prevCheckedList, { planId: roleId, featureId }];
            } else {
                return prevCheckedList.filter((item) => item.featureId !== featureId);
            }
        });
    };

    const updateFeatureMapping = async () => {
        const selectedFeatures = featureList
            .filter(feature => feature.isChecked)
            .map(feature => ({
                planId: roleId,
                featureId: feature.id
            }));

        try {
            const response = await window.apiService.updateFeatureMapping(selectedFeatures);
            if (response.data.status === "OK") {
                toast.success(response.data.message);
                loadAllPlanFeatures();
                loadFeatureById(roleId);
            }
        } catch (error) {
            console.log("Error updating feature mapping:", error);
        }
    };

    const deleteServiceFeature = async () => {
        if (toDelFeatureId) {
            try {
                const response = await window.apiService.deleteServiceFeature(toDelFeatureId);
                if (response.data.status == "OK") {
                    toast.success(response.data.message);
                    loadAllPlanFeatures();
                    loadFeatureById(roleId);
                    setDeletePopupShow(false);
                }
            } catch (error) {
                console.log(error);
                setDeletePopupShow(false);
            }
        }
    };

    return (
        <>
            <div className="chat container h-100">
                <Row>
                    <Col sm={12} md={12} lg={10} xl={10}>
                        <div className="role_container">
                            <Row className="m_b18 title_txt">
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    Choose Plan Features
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8} md={5} lg={5} xl={5}>
                                    <select
                                        className="rle_place form-control input_select fz-12"
                                        value={roleId}
                                        onChange={(e) => setRoleId(e.target.value)}
                                    >
                                        <option value="">Select Plan</option>
                                        {planList &&
                                            planList.map((plan) => (
                                                <option key={plan.id} value={plan.id}>
                                                    {plan.servicePlan}
                                                </option>
                                            ))}
                                    </select>
                                    {/* <Input
                                        className="inptRole rle_place"
                                        placeholder={editMode ? "Edit Feature Name" : "New Feature Name"}
                                        type="text"
                                        value={newFeatureName}
                                        onChange={(e) => {
                                            setNewFeatureName(e.target.value);
                                            setFeatureNameError("");
                                        }}
                                    />
                                    {featureNameError && <div className="text-danger fz_13">{featureNameError}</div>} */}
                                </Col>
                                {/* <Col sm={8} md={5} lg={5} xl={5}>
                                    <Input
                                        className="inptRole rle_place"
                                        placeholder="Description"
                                        type="text"
                                        value={newFeatureDesc}
                                        onChange={(e) => {
                                            setNewFeatureDesc(e.target.value);
                                            setFeatureDesError("");
                                        }}
                                    />
                                    {featureDesError && <div className="text-danger fz_13">{featureDesError}</div>}
                                </Col> */}
                                {/* <Col sm={6} md={2} lg={2} xl={2} className="mt-2 custom-col-icn d-flex p-0">
                                    {editMode ? (
                                        <>
                                            <TiTick className="c_pointer plus_icn m_r15" onClick={() => addFeature()} />
                                            <FaTimes
                                                className="c_pointer close_icn"
                                                onClick={() => {
                                                    setEditMode(false);
                                                    setNewFeatureName("");
                                                    setNewFeatureDesc("");
                                                    setEditedFeatureId(null);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <BsPlusLg onClick={() => addFeature()} className="c_pointer" />
                                    )}
                                </Col> */}
                            </Row>
                            {featureList && featureList.length > 0 ?
                                featureList.map((feature) => (
                                    <Row key={feature.id} className="mt-2">
                                        <Col sm={8} md={6} lg={6} xl={6}>
                                            {/* <div>
                                                <input
                                                    type="checkbox"
                                                    checked={feature.isChecked || false}
                                                    onChange={(event) => handleCheckboxChange(event, feature.id)}
                                                    style={{ marginRight: "12px" }}
                                                />
                                                {feature.featureName}
                                            </div> */}
                                            <div className="d-flex align-items-center">
                                                <label className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={feature.isChecked || false}
                                                        onChange={(event) => handleCheckboxChange(event, feature.id)}
                                                    />
                                                    <span className="switch" />
                                                </label>
                                                <label className="fz_14 ml-2">
                                                    {feature.featureName}
                                                </label>
                                            </div>
                                        </Col>
                                        {/* <Col sm={2} md={1} lg={1} xl={1}>
                                            <div className="m_r15">
                                                <HiOutlineTrash
                                                    onClick={() => showDeletePopup(feature.id)}
                                                    title="Delete"
                                                    className="ques_trash c_pointer"
                                                />
                                            </div>
                                        </Col> */}
                                    </Row>
                                )) : (
                                    <Row>
                                        <Col sm={12}>
                                            <div className="text-center fz_13 mt-2">No Plan Features Were Found</div>
                                        </Col>
                                    </Row>
                                )}
                        </div>
                    </Col>
                </Row>
                <Row className="m_b18 title_txt">
                    <Col sm={8} md={6} lg={6} xl={6}>
                        <Button variant="primary" className="del_bttn ml-3" onClick={() => updateFeatureMapping()}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
            {/* ----------- Delete Confirmation Popup ---------- */}
            < CustomModal
                show={deletePopupShow}
                handleClose={() => setDeletePopupShow(false)}
                performSubmit={() => deleteServiceFeature()}
                title="Delete Confirmation"
                btnText="Yes"
                body={
                    <>
                        <p>Are you sure to delete?</p>
                    </>
                }
            />
        </>
    );
};

export default PlanFeatureMapping;
