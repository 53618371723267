import React, { useRef, useState, useEffect, forwardRef } from "react";
import { HiLocationMarker } from "react-icons/hi";
import RatingBar from "../plugins/RatingBar";
import { Card, Col, Row } from "react-bootstrap";
import logo from "../../assets/images/tiip.png";
import { useParams } from 'react-router-dom';
import { useToast } from "../Toast";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ReviewShare = () => {
    const { userid } = useParams();
    const { reviewid } = useParams();
    const [location, setLocation] = useState("");
    const [shareData, setShareData] = useState([]);
    const [userData, setUserData] = useState({});
    const [scoreType, setScoreType] = useState("default");
    const [globalRating, setGlobalRating] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();
    const rmvMenFormat = (value) => {
        const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
        const transformedComment = value.replace(mentionRegex, "@$1");
        return transformedComment;
    };

    useEffect(() => {
        viewReview();
    }, [reviewid && userid]);

    const viewReview = async () => {
        if (reviewid && userid) {
            try {
                const response = await window.apiService.reviewShareView(userid, reviewid);
                setShareData(response.data);
            } catch (error) {
                toast.error("Error Occured");
            }
        }
    };

    const renderStars = (rating) => {
        const stars = [];
        const decimalPart = rating - Math.floor(rating);

        for (let index = 1; index <= 5; index++) {
            if (index < rating) {
                stars.push(<span key={index} className="fa fa-star checkedStr"></span>);
            } else if (index === Math.ceil(rating) && decimalPart > 0) {
                stars.push(
                    <span key={index} className="fa fa-star-half-o checkedStr"></span>
                );
            } else {
                stars.push(<span key={index} className="fa fa-star"></span>);
            }
        }

        return stars;
    };
    useEffect(() => {

        document.title = "title";

        // Update or create meta description
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute("content", "description123433");
        } else {
            const newDescriptionMeta = document.createElement("meta");
            newDescriptionMeta.setAttribute("name", "description");
            newDescriptionMeta.setAttribute("content", "testtttt");
            document.head.appendChild(newDescriptionMeta);
        }



    }, []);


    return (
        <>
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="test title" />
                <meta property="og:description" content="test comment12343" />
            </Helmet>
            <Card
                className="shadow mar_shre"
                style={{ width: "700px" }}
            >
                <Card.Body className="p-0 cd_bd">
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Row>
                            <Row className="">
                                <Col
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className="pad_shr text-center"
                                >
                                    <div>
                                        <img className="ImgLogo" src={logo} />
                                    </div>
                                    {/* <img className='share_img' src={usrImg}/> */}
                                    <Card.Title>
                                        {shareData.userName}
                                    </Card.Title>
                                    <div className="d-flex justify-content-center">
                                        <div className="starRtng">
                                            <div id="ratingStars">
                                                <span>{shareData && shareData.averageReviewScore}</span>
                                                {renderStars(shareData.averageReviewCount)}
                                                <span>({shareData && shareData.totalReviewCount})</span>
                                            </div>
                                        </div>
                                    </div>
                                    {shareData.location && (
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <HiLocationMarker />
                                            </div>
                                            <div className="">{shareData.location}</div>
                                        </div>
                                    )}
                                </Col>
                                {/* <div className="sep_dv"></div> */}
                                <Col sm={12} md={7} lg={7} xl={7} className="bdr_lt pad_shr">
                                    <div className="d-flex">
                                        <div className="fol_name text-start">Reviewed by : </div>
                                        <span className="mar_stty">
                                            {shareData.reviewerName}
                                        </span>
                                    </div>
                                    <div className="d-flex">
                                        <div className="fol_name text-start">Role : </div>
                                        <span className="mar_stty">
                                            {shareData.userRole}
                                        </span>
                                    </div>
                                    {shareData.comment && (
                                        <div className="">
                                            <div className="fol_name text-start">Comment :</div>
                                            <div className="">
                                                {shareData.comment
                                                    ? rmvMenFormat(shareData.comment)
                                                    : ""}
                                            </div>
                                        </div>
                                    )}
                                    <div className="cmtSec">
                                        <RatingBar
                                            disabled={true}
                                            likecount={shareData.likes}
                                            dislikecount={shareData.dislikes}
                                            labelVAlue={shareData.score}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Card.Body>
            </Card>
            <div className="Acc_sign">
                <div><a href="#" onClick={() => navigate("/login")}>
                    Login
                </a> here</div>
                Don't have an account?{" "}
                <a href="#" onClick={() => navigate("/registration")}>
                    Sign Up
                </a>
            </div>
        </>

    );
}

export default ReviewShare;