import "./inbox.css";
import chatImg from "../../../assets/images/chat.jpeg";
import { useToast } from "../../Toast";
import moment from "moment";
import { followId } from "../../../Redux/Actions/userAction";
import { FaPaperclip } from "react-icons/fa";
import loader from "../../../assets/images/loader.gif";
import fileImg from "../../../assets/images/file.jpg";
import { TiDelete } from "react-icons/ti";
import { IoIosCloseCircle } from "react-icons/io";
import lzString from "lz-string";
import { FaStop } from 'react-icons/fa';
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import profile from "../../../assets/images/profile.jpg";
import { Modal, Button } from "react-bootstrap";
import { HiLocationMarker } from "react-icons/hi";
import chatInputStyle from "../../../assets/css/chatInputStyle";
import { MentionsInput, Mention } from "react-mentions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactMic } from 'react-mic';
import { PiMicrophoneDuotone } from 'react-icons/pi';

export const Chat = forwardRef((props, ref) => {
  const [recordings, setRecordings] = useState([]);
  const [files, setFiles] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const lastMessageRef = useRef(null);
  const userId = useSelector((state) => state.user.userId);
  const [yesterData, setYesterData] = useState([]);
  const [toData, setToData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [users, setUsers] = useState([]);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(true);
  const fileInputRef = useRef(null);
  const [voiceRec, setVoiceRec] = useState(null);
  const startRecording = () => {
    onStart();
  };

  const stopRecording = () => {
    setIsRecording(false);
    setShow(false);
  };
  const onStart = () => {
    setIsRecording(true);
  };

  const onStop = (recordedBlob) => {
    const audioBlob = new Blob([recordedBlob.blob], {
      type: "audio/wav",
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];
      const compressedData = lzString.compressToBase64(base64Data);
      setRecordings((prevRecordings) => [...prevRecordings, compressedData]);
    };
    reader.readAsDataURL(audioBlob);

    console.log("Recording stopped:", recordings[0]);
    const file = new File([recordedBlob.blob], "recording.mp3", {
      type: recordedBlob.blob.type,
    });
    setVoiceRec(file);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let maxSizeInBytes;
      let fileType;

      if (file.type.startsWith('video/')) {
        maxSizeInBytes = 100 * 1024 * 1024; // 100 MB for video
        fileType = 'video';
      } else if (file.type.startsWith('audio/')) {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for audio
        fileType = 'audio';
      } else if (file.type.startsWith('image/')) {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for image
        fileType = 'image';
      } else {
        maxSizeInBytes = 10 * 1024 * 1024; // 10 MB for other file types
        fileType = 'file';
      }

      if (file.size > maxSizeInBytes) {
        toast.info(`File size exceeds the limit (${maxSizeInBytes / (1024 * 1024)} MB)`);
        event.target.value = null;
        setRemove(true);
        setSelectedFile(null);
        setFileType("");
        setFileName("");
        return;
      }

      setFileType(fileType);
      setSelectedFile(file);
      setFileName(file ? file.name : '');
      setRemove(true);
    }
  };

  const updateRemve = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setRemove(true);
    setSelectedFile(null);
    setFileType("");
    setFileName("");
  }

  const handleUpload = async () => {
    if (selectedFile.length > 0) {
      let formData = new FormData();
      formData.append('file', selectedFile[0]);

      try {
        const response = await window.apiService.chatUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === "OK") {
          console.log("log", response.data)
        } else if (response.data.status === "EXPECTATION_FAILED") {
          console.log("log", response.data)
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isSending) {
      event.preventDefault();
      setIsSending(true);
      sendMsg();
    }
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            console.log("fsf", match);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word + " ";
    });
  }

  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let Id = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [Id, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [Id, profId] = userIdPart.split("-");
      }
      if (name && Id) {
        if (Id !== userId) {
          dispatch(followId(Id));
          navigate("/dashboard/viewuser", {
            state: {
              Id,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  useEffect(() => {
    loadMsgs(props.id);
    // const intervalId = setInterval(() => loadMsgs(props.id), 5000);
    // return () => {
    // clearInterval(intervalId);
    // };
    userLists();
  }, [props.id]);
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };
  useImperativeHandle(ref, () => ({
    reloadMsg() {
      loadMsgs();
    },
  }));
  const handleReloadChats = () => {
    window.dispatchEvent(new Event("reloadLoadChats"));
  };
  const loadMsgs = async (id) => {
    if (id) {
      const senderParams = { receiverId: userId, senderId: id };
      try {
        const response = await window.apiService.chatListBySender(senderParams);
        mergeMsgs(response.data.recordInfo);
        setTimeout(() => {
          if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  function timesort(a, b) {
    let dateA = new Date(a.date).getTime();
    let dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  }
  const mergeMsgs = (msg) => {
    const ff = msg.filter((obj) => {
      if (obj.senderId == userId || obj.senderId == props.id) {
        return obj;
      }
    });
    const gg = ff.map((obj) => {
      return { ...obj, createdAt: new Date(obj.createdAt) };
    });
    const sortedAsc = gg.sort(
      (objA, objB) => Number(objA.createdAt) - Number(objB.createdAt)
    );
    sortedAsc.sort(timesort);
    const yesterday = new Date().getDate() - 1;
    const Yester_data = sortedAsc.filter(
      (st) => yesterday == new Date(st.createdAt).getDate()
    );
    Yester_data.reverse();
    setYesterData(Yester_data);
    const Today_data = sortedAsc.filter(
      (st) => new Date().getDate() == new Date(st.createdAt).getDate()
    );
    Today_data.reverse();
    setToData(Today_data);
    const date_data = sortedAsc.filter(
      (st) =>
        new Date().getDate() != new Date(st.createdAt).getDate() &&
        yesterday != new Date(st.createdAt).getDate()
    );
    date_data.reverse();
    setDateData(date_data);
  };
  const getTimeStamp = (value) => {
    const now = new Date();
    if (moment(now).format("HH:mm") == moment(value).format("HH:mm")) {
      return "Just Now";
    } else {
      return moment(value).format("HH:mm");
    }
  };
  const nameClick = (userId, userType, TypeId) => {
    dispatch(followId(userId, userType, TypeId));
    navigate("/dashboard/viewuser", {
      userId: userId,
      userType: userType,
      profileId: TypeId,
    });
  };
  const changeCmtText = (value) => {
    var words = value.split(/\s(?![^\[]*])/);
    var contents = words.map(function (word, i) {
      var separator = i < words.length - 1 ? " " : "";
      return word + separator;
    });
    let cmtText = contents.reduce(
      (accumulator, item) => (accumulator += item),
      ""
    );
    if (cmtText.length > 288) {
      var limit_char = cmtText.substring(0, 288);
      setMessage(limit_char);
    } else setMessage(cmtText);
  };




  const sendMsg = async () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);

    if ((message.trim() && message.length <= 288) || selectedFile || recordings.length !== 0) {
      setIsSending(true);
      const msgContent = {
        senderId: userId,
        receiverId: props.id,
        content: message,
      };
      try {
        const response = await window.apiService.addChat(msgContent);
        if (response) {
          setMessage("");
          loadMsgs(response.data.receiverId);
          handleReloadChats();
          setRemove(true);
          setFileName("");

          if (selectedFile || recordings.length !== 0) {
            let formData = new FormData();
            setLoading(true);
            if (selectedFile) {
              formData.append("file", selectedFile);
              formData.append("id", response.data.id);
              formData.append("type", fileType);
              formData.append("receiverId", response.data.receiverId);
              formData.append("senderId", userId);
            } else if (recordings.length !== 0) {
              formData.append("file", voiceRec);
              formData.append("id", response.data.id);
              formData.append("type", "audio");
              formData.append("receiverId", response.data.receiverId);
              formData.append("senderId", userId);
            }
            try {
              const uploadResponse = await window.apiService.chatUploadFile(formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              if (uploadResponse.data.status === "OK") {
                setMessage("");
                loadMsgs(uploadResponse.data.id);
                handleReloadChats();
                setLoading(false);
                setSelectedFile(null);
                setFileType("");
                setFileName("");
                setRecordings([]);
                setFiles(null);
                setIsRecording(false);
                setShow(true);
              } else if (uploadResponse.data.status === "EXPECTATION_FAILED") {
                setLoading(false);
              }
            } catch (error) {
              console.log(error);
              setLoading(false);
            }
          }
        }
      } catch (error) {
        toast.error("Error Occurred");
      } finally {
        setIsSending(false);
        setLoading(false);
      }
    } else if (!(message.length <= 288)) {
      setShowErrorModal(true);
    }
  };


  const renderFile = (fileType, filePath, fileName) => {
    if (!fileType) {
      return null;
    }
    if (fileType == "image") {
      return (
        <a href={filePath} target="_blank" rel="noopener noreferrer">
          <img height="200" width="200" src={filePath} alt="Image" />
        </a>
      );
    }

    if (fileType == "video") {
      return (
        <video controls width="300" height="200">
          <source src={filePath} />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (fileType == "audio") {
      return (
        <audio controls>
          <source src={filePath} />
          Your browser does not support the audio tag.
        </audio>
      );
    }

    if (fileType == "file") {
      return (
        <div className="d-flex border_att br_5px p-2 align-items-center" style={{ backgroundColor: "#efebe3" }}>
          <img
            width="45"
            height="45"
            src={fileImg}
            className="mr-2"
            alt="Image"
          />
          <a href={filePath} target="_blank" rel="noopener noreferrer" style={{ maxWidth: "200px", wordWrap: "break-word" }}>
            {fileName}
          </a>
        </div>
      );
    }

    return null;
  };
  const handleDeleteRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings.splice(index, 1);
    setRecordings(updatedRecordings);
    setShow(true);
  };
  const handleDeleteFiles = () => {
    setFiles(null);
  };

  const sendRequest = async (status, chatInfo) => {
    if (status && chatInfo) {
      const params = {
        senderId: chatInfo.senderId,
        senderUserType: chatInfo.senderBusinessId ? "Business" : "Personal",
        senderTypeId: chatInfo.senderBusinessId ? chatInfo.senderBusinessId : null,
        receiverId: chatInfo.receiverId,
        receiverUserType: chatInfo.receiverBusinessId ? "Business" : "Personal",
        receiverTypeId: chatInfo.receiverBusinessId ? chatInfo.receiverBusinessId : null,
        status: status,
        isRead: false
      };
      try {
        const response = await window.apiService.sendRequest(params);
        if (response.data.status === 'OK') {
          toast.success("Status updated successfully");
          loadMsgs(props.id);
        }
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  return (
    <>
      <div className="chat container">
        {props.id && !props.notShow ? (
          <div className="chatInfo">
            <div className="ban_messageInfo m-2">
              <img src={props.img ? props.img : profile} />
            </div>
            <div
              className={`d-flex flex-column ml-2 ${props.city != undefined && props.state != undefined
                ? "mt-4"
                : ""
                }`}
            >
              <a href="#" onClick={() => nameClick(props.id, "Personal", null)}>
                {props.name}
              </a>
              {props.city != undefined && props.state != undefined ? (
                <span>
                  <p className="fz-12 font-weight-normal">
                    <HiLocationMarker />
                    &nbsp;{props.city + "," + props.state}
                  </p>
                </span>
              ) : (
                ""
              )}
            </div>
            {/* <div className="chatIcons">
                    <IoMdVideocam className='Bs_cam'/>
                    <FaUserPlus className='Bs_cam'/>
                    <IoIosMore className='Bs_cam'/>
                </div> */}
          </div>
        ) : (
          ""
        )}
        {props.id && !props.notShow ? (
          <div>
            <div className="messages max_50">
              {dateData.map((chatInfo, i) =>
                chatInfo.senderId == props.id && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv">
                    <div className="left_message" key={i}>
                      <div className="messageInfo">
                        <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>{chatInfo.senderBusinessName ? chatInfo.senderBusinessName : "I'm"} requesting approval to view and comment/view on {chatInfo.receiverBusinessName ? chatInfo.receiverBusinessName : "your"} TiipTok feed. Would you like to grant access?</p>
                            <Button className="btn btn-default pad_btn" onClick={() => sendRequest('accepted', chatInfo)}>Accept</Button>
                            <Button className="btn btn-danger pad_btn" onClick={() => sendRequest('declined', chatInfo)} style={{ marginLeft: '10px' }}>Deny</Button>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>
                            {moment(chatInfo.createdAt).format("D MMM,HH:mm")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : chatInfo.senderId == userId && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv usr_right">
                    <div className="left_message w_35" key={i}>
                      <div className="messageInfo">
                        <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mr_15">
                      <div className="msg_pad"></div>
                      <div className="rt_messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>Request sent</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>
                            {moment(chatInfo.createdAt).format("D MMM,HH:mm")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
              {yesterData.length > 0 ? (
                <div className="d-flex justify-content-center">
                  <div className="day_label">Yesterday</div>
                </div>
              ) : (
                ""
              )}
              {yesterData.map((chatInfo, i) =>
                chatInfo.senderId == props.id && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv">
                    <div className="left_message" key={i}>
                      <div className="messageInfo">
                        <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>{chatInfo.senderBusinessName ? chatInfo.senderBusinessName : "I'm"} requesting approval to view and comment/view on {chatInfo.receiverBusinessName ? chatInfo.receiverBusinessName : "your"} TiipTok feed. Would you like to grant access?</p>
                            <Button className="btn btn-default pad_btn" onClick={() => sendRequest('accepted', chatInfo)}>Accept</Button>
                            <Button className="btn btn-danger pad_btn" onClick={() => sendRequest('declined', chatInfo)} style={{ marginLeft: '10px' }}>Deny</Button>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>
                            {"Yesterday " + getTimeStamp(chatInfo.createdAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : chatInfo.senderId == userId && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv usr_right">
                    <div className="left_message w_35" key={i}>
                      <div className="messageInfo">
                        <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mr_15">
                      <div className="msg_pad"></div>
                      <div className="rt_messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>Request sent</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>
                            {"Yesterday " + getTimeStamp(chatInfo.createdAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
              {toData.length > 0 ? (
                <div className="d-flex justify-content-center">
                  <div className="day_label">Today</div>
                </div>
              ) : (
                ""
              )}
              {toData.map((chatInfo, i) =>
                chatInfo.senderId == props.id && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv">
                    <div className="left_message" key={i}>
                      <div className="messageInfo">
                        <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>{chatInfo.senderBusinessName ? chatInfo.senderBusinessName : "I'm"} requesting approval to view and comment/view on {chatInfo.receiverBusinessName ? chatInfo.receiverBusinessName : "your"} TiipTok feed. Would you like to grant access?</p>
                            <Button className="btn btn-default pad_btn" onClick={() => sendRequest('accepted', chatInfo)}>Accept</Button>
                            <Button className="btn btn-danger pad_btn" onClick={() => sendRequest('declined', chatInfo)} style={{ marginLeft: '10px' }}>Deny</Button>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>{getTimeStamp(chatInfo.createdAt)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : chatInfo.senderId == userId && (chatInfo.content != undefined || chatInfo.content != null || (chatInfo.frdReqStatus && chatInfo.frdRequest)) ? (
                  <div className="left_msgdiv usr_right">
                    <div className="left_message w_35" key={i}>
                      <div className="messageInfo">
                        & <img
                          src={
                            chatInfo.senderImagePath
                              ? chatInfo.senderImagePath
                              : profile
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mr_15">
                      <div className="msg_pad"></div>
                      <div className="rt_messageContent">
                        {chatInfo.content && <p>{textWithLinks(chatInfo.content)}</p>}
                        {chatInfo.frdReqStatus == "accepted" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Accepted</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "declined" && chatInfo.frdRequest &&
                          <div>
                            <p>Request Declined</p>
                          </div>
                        }
                        {chatInfo.frdReqStatus == "pending" && chatInfo.frdRequest &&
                          <div>
                            <p>Request sent</p>
                          </div>
                        }
                        {loading ? (
                          <div className="pro-loader-center">
                            <img
                              className="loader-image"
                              src={loader}
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <span>
                          {chatInfo.fileType && chatInfo.filePath && (
                            <>
                              {renderFile(chatInfo.fileType, chatInfo.filePath, chatInfo.fileName)}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="msg_pad"></div>
                      <div className="messageContent">
                        <div className="day_div">
                          <span>{getTimeStamp(chatInfo.createdAt)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
              <div ref={lastMessageRef}></div>
            </div>
            <div className="input inpt_msg max_50" style={{ position: fileName ? 'relative' : 'static' }}>
              <MentionsInput
                className="men_inp"
                style={{
                  ...chatInputStyle,
                  maxHeight: '100px',
                  overflowY: 'scroll',
                  resize: 'none',
                }}
                // style={chatInputStyle}
                maxLength={288}
                onChange={(e) => {
                  changeCmtText(e.target.value);
                }}
                value={message}
                // onKeyPress={handleKeyPress}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent Enter from adding space or newline
                  }
                }}
                placeholder="Type here..."
              >
                <Mention
                  style={{ backgroundColor: "#efebe3" }}
                  trigger="@"
                  data={users}
                />
              </MentionsInput>
              <div >
                {recordings.map((compressedData, index) => (
                  <div key={index} className="d-flex mr_35">
                    <audio controls className="vice">
                      <source
                        src={`data:audio/wav;base64,${lzString.decompressFromBase64(compressedData)}`}
                        type="audio/wav"
                      />
                    </audio>
                    <IoIosCloseCircle className="cls_icn" onClick={() => handleDeleteRecording(index)} />
                  </div>
                ))}
              </div>
              {fileName && (
                <div className="message-container">
                  <span>{fileName}</span>
                  <TiDelete className="remove-icon" onClick={updateRemve} />
                </div>
              )}
              {files && <div>{files}
                <IoIosCloseCircle style={{ marginTop: '16px', fontSize: '20px', color: "red" }} onClick={() => handleDeleteFiles()} />
              </div>}
              <div className="audio-controls">
                {show && <div className="stpSty" onClick={isRecording ? stopRecording : startRecording} >
                  {isRecording ? <div className="blinking-icon"> <FaStop title="Stop" className="icnSty" style={{ color: "red" }} /> </div> : <PiMicrophoneDuotone title="Record" className="icnSty" />}
                </div>}
                <ReactMic
                  record={isRecording}
                  onStop={onStop}
                  onStart={onStart}
                  strokeColor="#000000"
                  backgroundColor="#FF4081"
                  className="react-mic-behind-button"
                />
              </div>
              <div className="send">
                {/* <input type="file" style={{display:"none"}} id="file"/>
                    <label htmlFor="file">
                        <FaPaperclip className='fa_paperclip'/>
                    </label>
                <input type="file" style={{display:"none"}} id="file"/>
                    <label htmlFor="file">
                        <BiImage className='bi_image'></BiImage>
                    </label> */}
                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  accept="image/*, video/*, audio/*, application/pdf"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-upload" className="file-upload-button c_pointer">
                  <FaPaperclip className='fa_paperclip' />
                </label>
                <button onClick={sendMsg} disabled={disabled}>Send</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="welcome_container">
            {/* <div className="d-flex justify-content-center"> */}
            <div className="">
              <h6>Let's Start Chatting!</h6>
            </div>
            <div className="">
              <img src={chatImg} style={{ height: "100px" }} />
            </div>
            {/* </div> */}
          </div>
        )}
        {/* ----------- limit exceed msg Popup ---------- */}
        <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
          <Modal.Header closeButton>
            <Modal.Title>Your message is too long</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please edit it down to 288 characters or split over multiple
            messages.
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="del_bttn"
              variant="primary"
              onClick={handleCloseErrorModal}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
});

export default Chat;
