import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import PhoneControl from "../CustomPhone/PhoneControl";
import { FiMinus, FiPlus } from "react-icons/fi";
import { MentionsInput, Mention } from "react-mentions";
import mentionInputStyle from "../../assets/css/MentionStyle";
import { connect } from "react-redux";
import { useToast } from "../Toast";
import { useSelector } from "react-redux";
import { commonUtils } from "../CommonUtils";
import loader from "../../assets/images/loader.gif";

const InviteModal = (props) => {
  const { showInvite, handleClose } = props;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneValidation, setPhoneValidation] = useState("");
  const [showRev, setShowRev] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roleRating, setRoleRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [quesRating, setQuesRating] = useState([]);
  const [ratingError, setRatingError] = useState("");
  const [selectedRoleError, setSelectedRoleError] = useState("");
  const [reviewQuestions, setReviewQuestions] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [dropdownOpenIdx, setDropdownOpenIdx] = useState("");
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState("");

  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    loadRoleList();
  }, []);

  const loadRoleList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: props.userType === "Personal"
          ? 1
          : props.userType === "Professional" ? 2 : props.userType === "Business" ? 3 : 1,
      });
      setRoleList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const CloseInvite = () => {
    handleClose();
    setEmailError("");
    setPhoneError("");
    setCommentError("");
    setRatingError("");
    setSelectedRoleError("");
    setRoleRating(0);
    setNameError("");
    setName("");
    setEmail("");
    setPhone("");
    setComment("");
    setShowRev(false);
    setReviewQuestions([]);
    setSelectedRole("");
    setRating(0);
    setmentionuserIds([]);
  };

  const loadsUsers = async () => {
    setLoading(true);
    const params = {
      userId: props.userType === "Business" ? props.busnAdminId : userId,
      profileId: props.userType != "Personal" ? props.profileId : null,
      userType: props.userType,
    };
    if (params.userId && params.userId !== "") {
      try {
        const response = await window.apiService.loadUsers(params);
        if (response.data.message == "Data loaded successfully.") {
          setLoading(false);
          setUserList(response.data.data);
          if (dropdownOpenIdx !== "")
            setUserData(response.data.data[dropdownOpenIdx]);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setLoading(false);
    }
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      setName(value);
      if (value) {
        setNameError("");
      }
    } else {
      setName(value.replace(/[^a-zA-Z\s]/g, ''));
    }
  };

  const handleEmailChange = (event) => {
    const Email = event.target.value;
    setEmail(Email);
    if (Email) {
      setEmailError("");
    }
  };
  const InviteUser = async () => {
    setCommentError("");

    let isValid = true;
    let isValidNew = true;
    if (!email.trim()) {
      setEmailError("Email is required.");
      isValid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email.trim());
    if (!isValidEmail && email) {
      setEmailError("Invalid Email.");
      isValid = false;
    }
    if (!name.trim()) {
      setNameError("Name is required.");
      isValid = false;
    }
    if (!phone.trim() || phone == "+1 undefined" || phone == "+91 undefined") {
      setPhoneError("Phone No is required.");
      isValid = false;
    }
    if (
      (comment.trim() == "" &&
        (roleRating ? roleRating : rating) == 0 &&
        selectedRole == "") ||
      (comment.trim() !== "" &&
        (roleRating ? roleRating : rating) !== 0 &&
        selectedRole !== "")
    ) {
      isValidNew = true;
    } else {
      isValidNew = false;
      if (comment.trim() == "") {
        setCommentError("Comment is required.");
      }
      if (roleRating == 0 && rating == 0) {
        setRatingError("Rating is required.");
      }
      if (selectedRole == "") {
        setSelectedRoleError("Role is required.");
      }
    }
    if (!isValid || !isValidNew) {
      return;
    }
    const user = {
      inviterId: props.userId,
      firstName: name,
      email: email,
      activated: 1,
      phone: phone,
      comment: comment,
    };
    setLoading(true);
    try {
      const response = await window.apiService.addInviteUser(user);
      const EmailMsg = "Email already exists.";
      const PhoneMsg = "Phone already exists.";
      const Msg = response.data.message;
      if (Msg == EmailMsg || Msg == PhoneMsg) {
        setLoading(false);
        toast.error(
          Msg == EmailMsg
            ? Msg + "(" + email + ")"
            : Msg == PhoneMsg
              ? Msg + "(" + phone + ")"
              : Msg
        );
      } else if (comment && response.data.id) {
        AddReview(response.data.id);
      } else if (response.data.id) {
        setLoading(false);
        toast.success(response.data.message);
        CloseInvite();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error Occured");
    }
  };

  const AddReview = async (id) => {
    const params = {
      userId: id,
      roleId: selectedRole,
      rating: roleRating ? roleRating : rating,
      comment: comment,
      reviewedBy: props.userId,
      isDeleted: 0,
      reviewQuestions: quesRating,
      mentionUserIds: mentionuserIds,
      isInviteReview: true,
    };
    try {
      const response = await window.apiService.addReview(params);
      setLoading(false);
      if (response.data.status == "OK") {
        toast.success("Invitation sent successfully");
        CloseInvite();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error occurred");
    }
  };

  const phoneChange = (value) => {
    const result = value.indexOf(" ");
    const ph = value.substring(result + 1);
    if (value != "" && ph.length === 14) {
      setPhoneValidation("");
      setPhoneError("");
    } else {
      setPhoneValidation("Phone No is required");
    }
    setPhone(value);
  };

  const handleRoleChange = (event) => {
    setRoleRating(0);
    setRating(0);
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);
    loadQuestions(selectedRole);
    if (selectedRole) {
      setSelectedRoleError("");
    }
  };
  const changeCmtText = (value) => {
    commonUtils.changeSimpleCmtText(
      value,
      setCommentError,
      setmentionuserIds,
      setComment
    );
  };

  const loadQuestions = async (id) => {
    if (id) {
      try {
        const response = await window.apiService.revQnsById({ roleId: id });
        const reviewQues = response.data.recordInfo.map((object) => {
          return { ...object, rating: "" };
        });
        setReviewQuestions(reviewQues);
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setReviewQuestions([]);
    }
  };
  const handleRoleStarRatingChange = (selectedRating, roleId, id, idx) => {
    const updatedReviewQuestions = reviewQuestions.map((obj, index) => {
      if (index === idx) {
        return { ...obj, rating: selectedRating };
      }
      return obj;
    });

    setReviewQuestions(updatedReviewQuestions);

    const result = updatedReviewQuestions.map((obj) => ({
      roleId: roleId,
      questionId: obj.id,
      rating: obj.rating || 0,
    }));
    setQuesRating(result);
    const averageRating = calculateAverageRating(result);
    setRoleRating(averageRating);
    if (averageRating) {
      setRatingError("");
    }
  };

  function calculateAverageRating(data) {
    let sumOfRatings = 0;
    let countOfRatings = 0;

    for (const item of data) {
      if (item.hasOwnProperty("rating")) {
        sumOfRatings += item.rating;
        countOfRatings++;
      }
    }

    if (countOfRatings === 0) {
      return 0;
    }

    return sumOfRatings / countOfRatings;
  }
  const handleStarRatingChange = (selectedRating) => {
    setRating(selectedRating);
    if (selectedRating) {
      setRatingError("");
    }
  };

  const handleOpen = () => {
    setShowRev(!showRev);
  };




  return (
    <>
      <Modal
        size="md"
        show={showInvite}
      // onHide={CloseInvite}
      >
        {loading ? (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        ) : (
          ""
        )}
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Invite User
          </Modal.Title>
          <span className="c_pointer" onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <div>
            {/* Input fields for name, email, and comment */}
            <div className="d-flex">
              <label
                class="col-sm-3 col-md-3 col-xl-3 p-0"
                style={{ marginRight: "10px" }}
              >
                Name:{" "}
              </label>
              <div className="p-0 col-sm-9 col-md-9 col-xl-9">
                <input
                  className="form-control input_ctrl"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={handleNameChange}
                />
                {nameError && (
                  <div className=" text-danger fz-12">{nameError}</div>
                )}
              </div>
            </div>
            <br />
            <div className="d-flex">
              <label
                class="col-sm-3 col-md-3 col-xl-3 p-0"
                style={{ marginRight: "10px" }}
              >
                Email:{" "}
              </label>
              <div className="col-sm-9 col-md-9 col-xl-9 p-0">
                <input
                  className="form-control input_ctrl"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <div className="text-danger fz-12">{emailError}</div>
                )}
              </div>
            </div>
            <br />
            <div className="d-flex">
              <label
                class="col-sm-3 col-md-3 col-xl-3 p-0"
                style={{ marginRight: "10px" }}
              >
                Phone:{" "}
              </label>
              <div className="col-sm-9 col-md-9 col-xl-9 p-0">
                <PhoneControl
                  phoneNo={phone}
                  isInvite={true}
                  mandatory={false}
                  onPhoneChange={phoneChange}
                  phoneValidation={phoneValidation}
                />
                {phoneError && (
                  <div className="text-danger fz-12">{phoneError}</div>
                )}
              </div>
            </div>
            <br />
          </div>
          <div className="rev_drpdn">
            <div className="border_rst__btm" style={{ padding: "5px" }}>
              <span className="c_pointer" style={{ marginRight: "13px", color: "blue" }} onClick={handleOpen}>
                {showRev ? <FiMinus /> : <FiPlus />}
              </span>
              Review
            </div>
            {showRev && (
              <div style={{ padding: "15px 0px 0px 14px" }}>
                <div>
                  {/* Input fields for name, email, and comment */}
                  <div className="d-flex">
                    <label
                      className="col-sm-3 col-md-3 col-xl-3 p-0"
                      style={{ marginRight: "10px" }}
                    >
                      Role:{" "}
                    </label>
                    <div className="col-sm-8 col-md-8 col-xl-8 p-0">
                      <select
                        className="form-control input_ctrl "
                        value={selectedRole}
                        onChange={handleRoleChange}
                      >
                        <option value="">Choose an option..</option>
                        {roleList &&
                          roleList.map((role) => (
                            <option value={role.id}>{role.name}</option>
                          ))}
                      </select>
                      <div>
                        {selectedRoleError && (
                          <div className="text-danger fz-12">
                            {selectedRoleError}
                          </div>
                        )}
                        {reviewQuestions &&
                          reviewQuestions.map((data, idx) => (
                            <div className="row" key={idx}>
                              <div className="col-6 col-md-6 col-lg-6 ques">
                                <p>{data.question}</p>
                              </div>
                              <div className="col-6 col-md-4 col-lg-6">
                                <StarRating
                                  rating={data.rating}
                                  id={data.id}
                                  idx={idx}
                                  roleId={data.userRoleId}
                                  isReview={true}
                                  onRatingChange={handleRoleStarRatingChange}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <label
                      class="col-sm-3 col-md-3 col-xl-3 p-0"
                      style={{ marginRight: "10px" }}
                    >
                      Comment:{" "}
                    </label>
                    <div className="col-sm-8 col-md-8 col-xl-8 p-0">
                      <MentionsInput
                        style={mentionInputStyle}
                        className="Comment_sty form-control"
                        maxLength={288}
                        onChange={(e) => {
                          changeCmtText(e.target.value);
                        }}
                        value={comment}
                        placeholder="Please add information supporting your review…"
                      >
                        <Mention
                          style={{ backgroundColor: "#efebe3" }}
                          trigger="@"
                          data={userList}
                        />
                      </MentionsInput>
                      {commentError && (
                        <div className=" text-danger fz-12">{commentError}</div>
                      )}
                    </div>
                  </div>
                  <span className="limt_cmt" style={{ marginRight: "28px" }}>
                    Limit : Max. 288 characters
                  </span>

                  <br />
                  <div className="d-flex">
                    <label
                      className="col-sm-3 col-md-3 col-xl-3 p-0 d-flex align-items-center"
                      style={{ marginRight: "10px" }}
                    >
                      Review:{" "}
                    </label>
                    <div className="col-sm-8 col-md-8 col-xl-8 p-0">
                      <StarRating
                        rating={roleRating != 0 ? roleRating : rating}
                        onRatingChange={handleStarRatingChange}
                        disabled={reviewQuestions.length != 0}
                      />
                      {ratingError && (
                        <div className="text-danger fz-12">{ratingError}</div>
                      )}{" "}
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <Button variant="primary" className="del_bttn" onClick={InviteUser}>
            Invite
          </Button>
          <Button variant="danger" className="del_bttn" onClick={CloseInvite}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileId: state.user.profileId,
    userType: state.user.userType,
    userTypeInfo: state.user.userTypeInfo,
    busnAdminId: state.user.busnAdminId,
    userId: state.user.userId,
  };
};
export default connect(mapStateToProps)(InviteModal);