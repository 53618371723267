import axios from "../axios";
import Axios from "axios";
const apiService = {
  loadprofileInfo: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/view",
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  refresh: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/refresh",
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  profileUpdate: async (id, params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/edit/" + id,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  professionUpdate: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/professional/add",
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  professionalList: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/lookup/userrole/byusertype",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userRoleAdd: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/userrole/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userRoleQuesAdd: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/userrole/add/question",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  businessUpdate: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/business/add",
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  nameExists: async (params) => {
    try {
      const response = await Axios.post(
        process.env.REACT_APP_BASEURL + "/user/name/exists",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteProfession: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/professional/deactivate",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  verifyCode: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/verify/verificationcode",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  imgUpload: async (formData, header) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/upload/photo",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendCode: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/send/verificationcode",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteImage: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/user/image/delete/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  logout: async (userId, params) => {
    try {
      const response = await Axios.post(
        process.env.REACT_APP_BASEURL + "/user/isactive/update/" + userId,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  activityUpdate: async (userId, params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/user/isactive/update/" + userId,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  revQnsById: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/review/questions/byroleid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  editRoleQns: async (id, params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/userrole/edit/question/" + id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteRoleQns: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/review/questions/delete/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadUserTypes: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/lookup/usertype"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  editUserRole: async (id, params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/userrole/edit/" + id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadUserRoles: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/lookup/userrole"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  profileScoreList: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/usersettings/profile/score/add"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addProfileScore: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/usersettings/profile/score/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateProfileScore: async (id, params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL +
        "/usersettings/profile/score/update/" +
        id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteProfileScore: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL +
        "/usersettings/profile/score/delete/" +
        id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  profMapList: async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/professional/mapping/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateProfMapList: async (id) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL +
        "/professional/mapping/list/update/" +
        id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addProfMapList: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/professional/mapping/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteUserRole: async (id) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/userrole/delete/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  lookupUsersList: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/lookup/users"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  searchUser: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/search",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  overallSearch: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/overall/search",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addFollower: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  unFollowUser: async (params) => {
    try {
      const response = axios.patch(
        process.env.REACT_APP_BASEURL + "/followers/unfollow/user",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addInviteUser: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/invite/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addReview: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addLike: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/add/liked",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userAuthentication: async (params) => {
    try {
      const response = Axios.post(
        process.env.REACT_APP_BASEURL + "/user/authenticate",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  followersList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/followed/view",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  followingList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/following/view",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  lookupAllUsersList: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/lookup/allusers"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userProfileView: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/profile/view",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getReviewListById: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/list/reviewerid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addComment: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/comments/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getReviewListByUserId: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/list/byuserid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addReport: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/reports/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addCommentLike: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/comment/add/liked",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reviewCmtList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/view/comments",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reviewStsUpdate: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/update/status",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reviewShare: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/share",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  revMentionListById: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/list/mention/byuserid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateRevMentionSts: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/mention/read",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateCmtMentionSts: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/comments/mention/read",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  cmtMentionListById: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/comments/list/mention/byuserid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  revUploadFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  latestReview: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/review/list/latest",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  cmtReplyList: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/comments/reply/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  viewReview: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/review/view/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  cmtReview: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/comments/view/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  chatListBySender: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/chatbox/list/bysender",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addChat: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/chatbox/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  inboxUserList: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/user/inboxuserlist"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  readMsg: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/chatbox/update/readmsg",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userInboxSearch: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/inbox/search",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  settingsAuthUpdate: async (id, params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/usersettings/auth/update/" + id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  settingsNotfyUpdate: async (id, params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL +
        "/usersettings/notification/update/" +
        id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  settingsUserView: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/usersettings/view/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadUsers: async (limit, offset, params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/list/" + limit + "/" + offset,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deCodeToken: async (token) => {
    try {
      const response = Axios.post(
        process.env.REACT_APP_BASEURL + `/user/decode/key/${token}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userViewById: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/view/byuserid",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  revDecodeToken: async (token) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + `/review/decode/key/${token}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  socialAuth: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/social/authenticate",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addUser: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userExists: async (params) => {
    try {
      const response = Axios.post(
        process.env.REACT_APP_BASEURL + "/user/exists",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateResetPassword: async (params) => {
    try {
      const response = Axios.post(
        process.env.REACT_APP_BASEURL + "/user/update/resetpassword",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (params) => {
    try {
      const response = Axios.post(
        process.env.REACT_APP_BASEURL + "/user/resetpassword",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reportPageList: async (params) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/reports/paginationlist",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reportView: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/reports/view/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateReportSts: async (id, status) => {
    try {
      const response = axios.put(
        process.env.REACT_APP_BASEURL +
        `/reports/${id}/update-review-status?reportStatus=${status}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  busnUserRoleList: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/business/userrole/list"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  busnList: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  busnUserList: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/user/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  allBusinessList: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/business/list"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateBusinessStatus: async (id, isVerifiedValue) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL +
        "/business/status/update/" +
        id +
        "?isVerifiedValue=" +
        isVerifiedValue
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  viewBusinessDetails: async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/business/view/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // autoLogin: async (token) =>{
  //     try {
  //         const response =  Axios.post(process.env.REACT_APP_BASEURL + "/user/autologin",{rememberToken:token })
  //         return response;
  //     } catch (error) {
  //         throw error;
  // },
  allBusnList: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/list"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  busnUserUpdate: async (id, params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/business/user/update/" + id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  busnUploadFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/business/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteAttachment: async (id, name) => {
    try {
      const response = axios.delete(
        process.env.REACT_APP_BASEURL +
        "/business/attachment/delete/" +
        id +
        "/" +
        name
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  inviteUserBusnList: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/invite/business/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addNotes: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/business/notes/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadBusinessNote: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/notes/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addBusinessInviteUser: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/buisness/invite/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  removeBusnUsr: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/business/remove",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  profUploadFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/professional/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteProfImage: async (id) => {
    try {
      const response = axios.delete(
        process.env.REACT_APP_BASEURL + "/professional/file/delete/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteLogoImage: async (id) => {
    try {
      const response = axios.delete(
        process.env.REACT_APP_BASEURL + "/business/logo/delete/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  uploadLogoFile: (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/business/logo/upload",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  reviewUserInfo: (request) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/review/Info",
        request
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  kycfirstapi: async (kycdata) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/kyc/submitKyc",
        kycdata
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  kycdocumentupload: async (documentdata) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/kyc/upload/document",
        documentdata
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  verifyKyc: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/kyc/verify/document"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  updateKycVerification: async (id, verification) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASEURL}/kyc/kycverified/${id}`,
        verification,
        {}
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addBusinessServiceProvider: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/business/service/provider/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listBusinessServiceProviders: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/service/provider/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  verifiedBusnList: async () => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/business/verified/list"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  chatUploadFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/chatbox/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addPost: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  uploadPostFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  postList: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/post/list/byusertype",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addPostComment: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/comment/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addPostLike: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/add/liked",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addPostCommentLike: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/add/comment/liked",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getPostCommentReplies: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/post/comment/replies",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  settingsBusnScore: async (id, params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL +
        "/usersettings/business/score/update/" +
        id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getRevsBysStars: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/reviews/bystars",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  kybApicall: async (kybdata) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/kyb/kybbussiness",
        kybdata
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  kybverificationApi: async (kybverifydata) => {
    try {
      const response = axios.put(
        process.env.REACT_APP_BASEURL + "/kyb/Kybverification",
        kybverifydata
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userFollowersList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/followed/list",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  userFollowingList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/following/list",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  getMarketplaceItems: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/marketplace/list"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getMarketplaceItemById: async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/marketplace/list/" + id
      );
      return response.data; // Ensure to return the data
    } catch (error) {
      throw error;
    }
  },

  // addMarketplaceItem: async (params, data) => {
  //   try {
  //     const response = await axios.post(
  //       process.env.REACT_APP_BASEURL +
  //         `/marketplace/add?sellerId=${params.sellerId}&createdBy=${params.createdBy}&categoryId=${params.categoryId}`,
  //       data
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  addMarketplaceItem: async (data) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/marketplace/add",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateMarketplaceItem: async (id, data) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASEURL + "/marketplace/update/" + id,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteMarketplaceItem: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/marketplace/delete/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  uploadMarketplaceFile: async (formData) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/marketplace/upload/file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Assuming backend returns a success message or status
    } catch (error) {
      throw new Error(`Error uploading file: ${error.message}`);
    }
  },
  getCategories: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/marketplace/category/list"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addCategory: async (params) => {
    try {
      const response = await axios.post(
        // process.env.REACT_APP_BASEURL + `marketplace/category/add?id=${params}`,
        process.env.REACT_APP_BASEURL + `/marketplace/category/add`,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateCategory: async (id, params) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASEURL + "/marketplace/category/update/" + id,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteCategory: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/marketplace/category/delete/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  profileChartInfo: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/profile/chart",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateCultureScore: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/culturescore/add",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  createGroup: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/group/add/members",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getChatGroups: async (userId) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/group/list/" + userId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  grpImgUpload: async (formData, header) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/group/upload/image",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  grpImgDelete: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/group/image/delete/"+id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getGrpInfo: async (id) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/group/view/"+id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addGrpMsg: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/group/add/message",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listGrpMsgs: async (grpId, userId) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/group/list/messages/" + grpId + '/' + userId
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listGrpMems: async (id) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/group/members/list/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  leaveGrp: async (groupId, userId,rmverId,params) => {
    try {
      const response = axios.put(
        process.env.REACT_APP_BASEURL + "/group/" + groupId + "/member/" + userId +"/"+rmverId+"/leave", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateGrp: async (grpId, params) => {
    try {
      const response = axios.put(
        process.env.REACT_APP_BASEURL + "/group/update/" + grpId, params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addFollowNotify: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/followers/add/review_notification", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listFollowNotify: async (params) => {
    try {
      const response = axios.get(
        process.env.REACT_APP_BASEURL + "/followers/list/review_notifications", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  grpChatUploadFile: async (formData, header) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/group/upload/file",
        formData,
        header
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listRevNotify: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/reviewnotification/list", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateRevNotify: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/user/reviewnotification/update", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateRevNotifyStatus: async (params) => {
    try {
      const response = axios.put(
        process.env.REACT_APP_BASEURL + "/followers/following/review/notification/update", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addHelp: async (params) => {
    try {
      const response = await axios.post(

        process.env.REACT_APP_BASEURL + `/help/add`,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateTiiptokPref: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/tiiptok/preferences/update", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  listTiiptokPref: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/tiiptok/preferences/list", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendRequest: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/tiiptok/preferences/friend/add", params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  friendList: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/tiiptok/preferences/friend/list",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  grpReadMsg: async (params) => {
    try {
      const response = axios.post(
        process.env.REACT_APP_BASEURL + "/group/update/readgrpmsg",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  removeFriend: async (params) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/tiiptok/preferences/friend/delete",
        { data: params }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  pinReview: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/review/pin",
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reviewShareView: async (userId,reviewId) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/share/viewreview/" + userId + "/" + reviewId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  loadPlans: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/serviceplan/list"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addServicePlan: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/serviceplan/add",params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  addPlanFeature: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/serviceplan/add/features",params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadAllFeatures: async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/serviceplan/features"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  loadFeatureById: async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASEURL + "/serviceplan/features/byid/"+id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteServicePlan: async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/serviceplan/delete/"+id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteServiceFeature:async (id) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASEURL + "/serviceplan/feature/delete/"+id
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateFeatureMapping: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/serviceplan/update/feature/mapping",params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendPlanUpOtp: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/serviceplan/send/otp",params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  verifyPlanUpOtp: async (params) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "/serviceplan/verify/otp",params
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
window.apiService = apiService;
