import "./inbox.css";
import profile from "../../../assets/images/profile.jpg";
import { Nav, Col, Row, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useToast } from "../../Toast";
import { ImUserPlus, ImCross } from "react-icons/im";
import { FiSearch } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { followId } from "../../../Redux/Actions/userAction";
import { userAction } from "../../../Redux/Actions/userAction";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Groups from "./groups";
import add_grp from "../../../assets/images/add_grp.png";
import CustomModal from "../../plugins/CustomModal";
import { FaPlus } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import loader from "../../../assets/images/loader.gif";
export function Chats({ handleEventKey }) {
  const [chatList, setChatList] = useState([]);
  const [viewChat, setViewChat] = useState([]);
  const [allChat, setAllChat] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [addGrpModal, setAddGrpModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [activeUserId, setActiveUserId] = useState(null);
  const [activeChatType, setActiveChatType] = useState("direct");
  const [chatId, setChatId] = useState(location.state?.userId);
  const [showDirectMsg, setShowDirectMsg] = useState(location.state?.showDirect ? true : false);
  const [showGroups, setShowGroups] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [config, setConfig] = useState([]);
  const [grpName, setGrpName] = useState("");
  const [grpDesc, setGrpDesc] = useState("");
  const [showMem, setShowMem] = useState(false);
  const [grpMembers, setGrpMembers] = useState([]);
  const userId = useSelector((state) => state.user.userId);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filter, setfilter] = useState(false);
  const [allData, setAllData] = useState([]);
  const [chatGrps, setChatGrps] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, []);
  useEffect(() => {
    if (userId) {
      setGrpMembers([{
        'userId': userId, 'role': 'Admin', 'imagePath': '', 'name': '', 'history': true,
        'addedBy': userId
      }]);
    }
  }, [userId]);

  useEffect(() => {
    if (activeChatType === "direct")
      loadChats();
    else
      fetchGroups();
    const reloadLoadChatsHandler = () => {
      if (activeChatType === "direct")
        loadChats();
      else
        fetchGroups();
    };
    window.addEventListener("reloadLoadChats", reloadLoadChatsHandler);
    return () => {
      window.removeEventListener("reloadLoadChats", reloadLoadChatsHandler);
    };
  }, []);
  useEffect(() => {
    const reloadGroups = (event) => {
      const { id } = event.detail;
      fetchGroups(id);
    };
    window.addEventListener("fetchGroups", reloadGroups);
    return () => {
      window.removeEventListener("fetchGroups", reloadGroups);
    };
    // }
  }, []);
  const handleOpen = () => {
    setShowMem(!showMem);
    handleUserSearch(userSearchTerm);
  };
  useEffect(() => {
    setFilterData(filterUserData(allData));
  }, [grpMembers, allData]);
  const addAsGrpMember = (id, name, image) => {
    if (grpMembers.length <= 11) {
      const newMember = {
        'userId': id, 'role': 'Member', 'imagePath': image, 'name': name, 'history': true,
        'addedBy': userId
      };
      setGrpMembers(prev => [...prev, newMember]);
      setUserSearchTerm("");
    }
    else {
      toast.error("You cannot add more than 10 members to this group");
    }
  };
  const rmvGrpMemById = (id) => {
    const updatedItems = grpMembers.filter(item => item.userId !== id);
    setGrpMembers(updatedItems);
  }
  const filterUserData = (data) => {
    const memberIds = grpMembers.map(member => member.userId);
    return data.filter(item => !memberIds.includes(item.id));
  };
  useEffect(() => {
    if (userSearchTerm)
      handleUserSearch(userSearchTerm);
    else setFilterData([]);
  }, [userSearchTerm])
  const handleUserSearch = async (userSearchTerm) => {
    if (userSearchTerm) {
      try {
        let formData = new FormData();
        formData.append("keyword", userSearchTerm);
        formData.append("businessId", '');
        const response = await window.apiService.searchUser(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setAllData(response.data.data);
        setFilterData(filterUserData(response.data.data));
      } catch (error) {
        console.log(error);
      }
    } else {
      setFilterData([]);
    }
  };
  const handleImageChange = (event) => {
    setImagePreview(URL.createObjectURL(event.target.files[0]));
    setImagePath(event.target.files[0]);
  };
  const createImage = (file) => {
    var image = new Image();
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePath(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const uploadImage = async (id) => {
    const myNewFile = new File([imagePath], imagePath.name.replace(/\s/g, ""), {
      type: imagePath.type,
    });
    setLoading(true);
    createImage(myNewFile);
    let formData = new FormData();
    formData.append("file", myNewFile);
    formData.append("groupId", id);
    try {
      const response = await window.apiService.grpImgUpload(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status == "OK") {
        setLoading(false);
        toast.success("Group created sucessfully");
        setImagePreview("");
        setImagePath("");
      } else if (response.data.status == "EXPECTATION_FAILED") {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleCreateGrp = async () => {
    const params = {
      'name': grpName,
      'description': grpDesc,
      'members': grpMembers
    }
    try {
      const response = await window.apiService.createGroup(params);
      if (response.data.status === "OK") {
        if (response.data.id) {
          if (imagePath.name) {
            uploadImage(response.data.id);
          } else {
            console.log("kkk", response.data.message)
            if (response.data.message === "Group already exists.")
              toast.info(response.data.message);
            else
              toast.success(response.data.message);
          }
        }
        fetchGroups();
        setGrpName("");
        setGrpDesc("");
        setGrpMembers([{
          'userId': userId, 'role': 'Admin', 'imagePath': '', 'name': '', 'history': true,
          'addedBy': userId
        }]);
        setUserSearchTerm("");
        setAddGrpModal(false);
        setShowGroups(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    loadChats();
    fetchGroups();
  }, [activeUserId]);
  useEffect(() => {
    fetchGroups();
  }, [userId]);
  useEffect(() => {
    if (viewChat.length != 0 || chatGrps.length != 0) {
      let chat = {};
      if (activeChatType === 'direct')
        chat = viewChat.filter((chat) => chat.id == chatId);
      else
        chat = chatGrps.filter((chat) => chat.id == chatId);
      setActiveUserId(chatId);
      if (chat.length != 0) {
        handleEventKey(
          chat[0].id,
          chat[0].name,
          chat[0].imagePath,
          chat[0].city,
          chat[0].state,
          chat[0].description,
          activeChatType
        );
        window.dispatchEvent(new CustomEvent("loadGrpMems", { detail: { activeUserId } }));
        updateReadStatus(chat);
      }
    }
  }, [chatId && viewChat || chatGrps]);
  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == userId ? "" : "cmt_icon fz-12 c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word + " ";
    });
  }
  const fetchGroups = async (id) => {
    setActiveUserId(id ? id : activeUserId);
    if (userId) {
      try {
        const response = await window.apiService.getChatGroups(userId);
        setChatGrps(response.data);
        const sortedChatList = response.data.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
        const activeUserChat = response.data.find(chat => chat.id === activeUserId);
        const uniqueSortedChatList = sortedChatList.filter(chat => chat.id !== activeUserId);
        const finalChatList = activeUserChat ? [activeUserChat, ...uniqueSortedChatList] : uniqueSortedChatList;
        setChatGrps(finalChatList);
        const activeChatGroup = finalChatList.find(chat => chat.id === activeUserId);
        if (activeChatGroup) {
          handleEventKey(
            activeChatGroup.id,
            activeChatGroup.name,
            activeChatGroup.imagePath,
            activeChatGroup.city,
            activeChatGroup.state,
            activeChatGroup.description,
            activeChatType
          );
        } else {
          console.log('Active user chat group not found.');
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
  }
  const loadChats = async () => {
    setAllChat(false);
    try {
      const response = await window.apiService.inboxUserList();
      setViewChat(response.data.recordInfo);
      const filteredChatList = response.data.recordInfo.filter(
        (chat) => chat.conversationCount === 1
      );

      const sortedChatList = filteredChatList.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
      const activeUserChat = response.data.recordInfo.find(chat => chat.id === activeUserId);
      // Filter out any instance of activeUserChat from the sortedChatList
      const uniqueSortedChatList = sortedChatList.filter(chat => chat.id !== activeUserId);
      // Add the active user chat to the top of the unique sorted list
      const finalChatList = activeUserChat ? [activeUserChat, ...uniqueSortedChatList] : uniqueSortedChatList;
      setChatList(finalChatList);
      setShowCloseIcon(false);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const listUser = async () => {
    try {
      const response = await window.apiService.inboxUserList();
      if (chatList.length > 0) {
        const updatedList = response.data.recordInfo.filter((newItem) =>
          !chatList.some((oldItem) => oldItem.id === newItem.id)
        );

        setChatList([...updatedList]);
      } else {
        setChatList(response.data.recordInfo);
      }

      setAllChat(true);
      setShowCloseIcon(true);
      setShowDirectMsg(true);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const authToken = useSelector((state) => state.user.token);
  const userType = useSelector((state) => state.user.userType);
  const profileId = useSelector((state) => state.user.profileId);
  const Refresh = async () => {
    if (authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: profileId,
        authToken: authToken,
        userType: userType ? userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const clearGrpInfo = () => {
    setGrpName("");
    setImagePreview("");
    setImagePath("");
    setGrpDesc("");
    setGrpMembers([]);
    setUserSearchTerm("");
    setShowMem(!showMem);
    setAddGrpModal(false);
  }
  const updateReadStatus = async (chat) => {
    if (chat.type == "group") {
      const params = {
        groupId: chat.id,
        senderId: chat.senderId,
        receiverId: chat.receiverId,
        isRead: true,
      };
      if (chat.isRead == false) {
        try {
          const response = await window.apiService.grpReadMsg(params);
          if (response.data.status == "OK") {
            loadChats();
            Refresh();
            fetchGroups();
          }
        } catch (error) {
          toast.error("Error Occured");
        }
      }
    }
    else {
      const params = {
        senderId: chat.senderId == userId ? chat.senderId : chat.receiverId,
        receiverId: chat.senderId == userId ? chat.receiverId : chat.senderId,
        isRead: true,
      };
      if (chat.isRead == false) {
        try {
          const response = await window.apiService.readMsg(params);
          if (response.data.status == "OK") {
            loadChats();
            Refresh();
          }
        } catch (error) {
          toast.error("Error Occured");
        }
      }
    }

  };
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleUserChange = (event) => {
    setUserSearchTerm(event.target.value);
  };

  const handleUserKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUserSearch(userSearchTerm);
    }
  };
  const handleSearch = async () => {
    let formData = new FormData();
    formData.append("keyword", searchTerm);
    try {
      const response = await window.apiService.userInboxSearch(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setChatList(response.data.data);
      setShowDirectMsg(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Nav variant="pills" className="flex-column navStyle">
        <div className="user_ad">
          <div class="input-group cht_srch m-2">
            <input
              class="form-control py-2 border-right-0 border"
              placeholder="Search User..."
              value={searchTerm}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              id="ch_srch_input"
            />
            <span class="input-group-append">
              <div class="input-group-text ch_in_icon bg-transparent">
                <FiSearch className="ch_search_icon c_pointer" onClick={handleSearch} />
              </div>
            </span>
          </div>
          {showCloseIcon ? (
            <ImCross
              title="Close"
              className="close-icon c_pointer mr_12 ur_icon"
              onClick={loadChats}
            />
          ) : (
            <ImUserPlus
              title="Add User"
              className="c_pointer fs_20 mr_12 ur_icon"
              onClick={listUser}
            />
          )}
          <img src={add_grp} height={18} width={18} title="Add Group" className="c_pointer opacity-6" onClick={() => setAddGrpModal(true)} />
        </div>
        <div className="chat_div">
          <span className="c_pointer fz_14" onClick={() => setShowDirectMsg(!showDirectMsg)}>Direct Messages
            {!showDirectMsg ? <IoMdArrowDropdown /> :
              <IoMdArrowDropup />} </span>
          {showDirectMsg && <div className={allChat ? "cht_list mt-2" : "bg-white mt-2"}>
            {chatList && chatList.length > 0 ? chatList.map((chat, i) => (
              <Nav.Item
                className={`userchathov ${activeUserId === chat.id && activeChatType === "direct" ? "bl_msg_list" : ""
                  }`}
                key={i}
              >
                <Nav.Link
                  onClick={(event) => {
                    setChatId(chat.id);
                    setActiveChatType("direct");
                    setActiveUserId(chat.id);
                    handleEventKey(
                      chat.id,
                      chat.name,
                      chat.imagePath,
                      chat.city,
                      chat.state,
                      '',
                      "direct"
                    );
                    // window.dispatchEvent(new Event("loadGrpMems"));
                    updateReadStatus(chat);
                  }}
                >
                  <div className="userchat">
                    <div>
                      <img src={chat.imagePath ? chat.imagePath : profile} />
                      {chat.isActive == 1 ? (
                        <span className="act_grn"></span>
                      ) : (
                        <span className="act_red"></span>
                      )}
                    </div>
                    <div className="userchatInfo">
                      <span>
                        <p className="fz_14">{chat.name}</p>
                      </span>
                      {chat.location != undefined &&
                        chat.location != (null || "null,null") ? (
                        <span>
                          <p className="fz-12 font-weight-normal">
                            <HiLocationMarker />
                            &nbsp;{chat.location}
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                      {chat.message ? (
                        <p>
                          {userId === chat.senderId ? "You: " : ""}
                          {chat.message && chat.message.length > 10
                            ? textWithLinks(chat.message.substring(0, 10))
                              .map((word, index) => (
                                <span className="fz-12" key={index}>
                                  {word}{" "}
                                </span>
                              ))
                              .concat("...")
                            : textWithLinks(chat.message).map((word, index) => (
                              <span className="fz-12" key={index}>
                                {word}{" "}
                              </span>
                            ))}
                        </p>
                      ) : (
                        ""
                      )}
                      {chat.isRead == false ? (
                        <span className="unread-text">Unread</span>
                      ) : null}
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            )) : <Card className="bg-white fz_14 text-center">No chats were found</Card>}
          </div>}
        </div>
        <div className="chat_div">
          <span className="c_pointer fz_14" onClick={() => setShowGroups(!showGroups)}>Groups
            {!showGroups ? <IoMdArrowDropdown /> :
              <IoMdArrowDropup />}</span>
          {showGroups && <Groups groups={chatGrps} activeUserId={activeUserId} setActiveUserId={setActiveUserId} activeChatType={activeChatType} setActiveChatType={setActiveChatType}
            setChatId={setChatId} handleEventKey={handleEventKey} updateReadStatus={updateReadStatus} />}
        </div>
      </Nav>
      {chatList.length > 0 ? <hr className="my-3" /> : ""}
      <CustomModal
        show={addGrpModal}
        handleClose={() => clearGrpInfo()}
        post={true}
        title="Add Group"
        body={
          <>
            {loading ? (
              <div className="pro-loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            ) : (
              ""
            )}
            <div className="d-flex">
              <Col lg="2" md="2" sm="2" xl="2" className="p-0">
                <div className="profile-img">
                  <img
                    style={{ width: "45px", height: "45px" }}
                    src={imagePreview ? imagePreview : profile}
                    alt=""
                  />
                  <label className="grp_img">
                    <FaPlus className="name_blue fs_20" />
                    <input
                      id="interviewer_profile_photo"
                      type="file"
                      accept=".jpg, .svg, .jpeg, .png, .bmp, .gif, .jfif"
                      style={{
                        width: "0px",
                        height: "0px",
                        overflow: "hidden",
                      }}
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
              </Col>
              <Col lg="10" md="10" sm="10" xl="10" className="p-0">
                <input class="form-control py-2 border mb-2" value={grpName} onChange={(e) => setGrpName(e.target.value)}
                  placeholder="Group name"></input>
                <input class="form-control py-2 border" value={grpDesc} onChange={(e) => setGrpDesc(e.target.value)}
                  placeholder="Description"></input></Col>
            </div>
            <div className="rev_drpdn mem_bor mt-2 mb-2">
              <div className="mem_bor_btm c_pointer" style={{ padding: "5px" }} onClick={handleOpen}>
                Members
                <span className="c_pointer" style={{ marginLeft: "13px", color: "blue" }}>
                  {showMem ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                </span>
              </div>
              {showMem && (
                <div style={{ padding: "10px" }}>
                  <Row className="mr-2 ml-2">
                    {grpMembers.map((member) => member.userId !== userId &&
                      <Col lg="4" md="4" sm="4" xl="4" className="pr-2 pl-2">
                        <div className="d-flex align-items-center justify-content-center gap_5 name_tag mb-2">
                          <img className="rounded-circle" src={member.imagePath ? member.imagePath : profile} height={20} width={20} />
                          <p className="mb-0 font-weight-400 fz_14" title={member.name}>{member.name.length > 8
                            ? member.name.substring(0, 8) + "..." : member.name}</p>
                          <IoClose className="rounded-circle text-white bg-red fz-12 c_pointer" onClick={() => rmvGrpMemById(member.userId)} /></div>
                      </Col>
                    )}
                  </Row>
                  <div class="input-group hr_search rounded-0 m-2">
                    <input
                      class="form-control py-2 border-right-0 border-top-0 rounded-0 border"
                      placeholder="Search for name, number, or email..."
                      value={userSearchTerm}
                      onChange={handleUserChange}
                      onKeyPress={handleUserKeyPress}
                      id="usr_srch_input"
                    />
                    {userSearchTerm != "" && (
                      <span class="input-group-append c_pointer">
                        <div class="input-group-text bg-white border-top-0 rounded-0">
                          <IoClose
                            className="search_icon"
                            onClick={() => setUserSearchTerm("")}
                          />
                        </div>
                      </span>
                    )}
                    <span class="input-group-append c_pointer">
                      <div class="input-group-text bg-transparent border-top-0 rounded-0">
                        <FiSearch
                          className="search_icon"
                          onClick={() => handleUserSearch(userSearchTerm)}
                        />
                      </div>
                    </span>
                  </div>
                  {filterData && filterData.length > 0 && userSearchTerm ? (
                    <Card className="m-2 me_revList mx_h_45vh">
                      {filterData && filterData.length > 0
                        ? filterData.map((data, i) => (
                          <Card className="rounded-0 px-2 py-2 c_pointer"
                            onClick={() => {
                              addAsGrpMember(data.id, data.name, data.imagePath);
                              setFilterData([]);
                              setAllData([]);
                            }}>
                            <div className="d-flex align-items-center">
                              <Col lg="2" md="2" sm="2" xl="2">
                                <img
                                  className="brd_rad"
                                  width="40"
                                  height="40"
                                  src={
                                    data.imagePath
                                      ? data.imagePath
                                      : profile
                                  }
                                />
                              </Col>
                              <Col lg="8" md="8" sm="8" xl="8">
                                <div className="d-flex">
                                  <p className="mb-0 font-weight-400 fz_14">
                                    {data.name}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <p
                                    className="mb-0 font-weight-400 fz-12"
                                    title={data.email}
                                  >
                                    {/* {data.email.length > 27
                                          ? data.email.substring(0, 27) + "..."
                                          : data.email} */}
                                    {data.email}
                                  </p>
                                </div>
                              </Col>
                              <Col lg="2" md="2" sm="2" xl="2">
                                {/* {data.isAdded ? (
                              <FaUserCheck
                                className="fz-22 text-green"
                                title="Added"
                              />
                            ) : (
                              <FaUserPlus
                                className="fz-22 text-blue c_pointer"
                                title="Add as user"
                                onClick={() => addBusinessUser(data)}
                              />
                            )} */}
                              </Col>
                            </div>
                          </Card>
                        ))
                        : ""}
                    </Card>
                  ) : (
                    ""
                  )}
                </div>
              )}

            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                className="btn btn-default cancel_btn" onClick={() => {
                  setGrpName(""); setGrpDesc(""); setGrpMembers([]); setUserSearchTerm(""); setShowMem(!showMem); setAddGrpModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!grpName}
                type="submit"
                className="btn btn-default" onClick={() => handleCreateGrp()}
              >
                Create
                {/* {isLoading && (
                  <img
                    className="btn-loader-image"
                    src={loader}
                    alt="Loading..."
                  />
                )} */}
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}

export default Chats;
